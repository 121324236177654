
import {
  IonPage,
  IonContent,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import {
  logoWhatsapp,
  personOutline,
  mailOutline,
  callOutline,
  pencilOutline,
  documentTextOutline,
} from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useI18n } from "vue-i18n";
//import { useRouter } from "vue-router";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";

export default defineComponent({
  name: "ContactUs",
  setup() {
    const { t } = useI18n();
    //const router = useRouter();
    const { createLoading, openToast } = useGlobalFunctions();
    const contact = ref({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    const subjectOptions = ref([]);
    const icons = reactive({
      logoWhatsapp,
      personOutline,
      mailOutline,
      callOutline,
      pencilOutline,
      documentTextOutline,
    });
    const contactDescrip = ref();
    const contactMap = ref();

    const getSubjectOptions = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getConfigs"), {
          paths: "contacts/contacts/subject_options",
        })
        .then(async (response: any) => {
          const subjectOptionsResp =
            response["contacts/contacts/subject_options"];
          if (subjectOptionsResp) {
            if (
              !Object.prototype.hasOwnProperty.call(subjectOptionsResp, "error")
            ) {
              subjectOptions.value = subjectOptionsResp.split(/\r?\n/);
            }
          }
        });
    };

    const contactAction = async (event: any) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      const contactData = {
        name: contact.value.name,
        email: contact.value.email,
        phone: contact.value.phone,
        subject: contact.value.subject,
        message: contact.value.message,
      };
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("sendContactMessage"),
          contactData
        )
        .then(async (response: any) => {
          loading.dismiss();
          if (response.success) {
            openToast(t("contact.success"), "success");
            contact.value.name = "";
            contact.value.email = "";
            contact.value.phone = "";
            contact.value.subject = "";
            contact.value.message = "";
          } else {
            openToast(t("apiMsgs." + response.error.code), "danger");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    function getCmsBlock() {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("cmsBlock"), {
          identifier: "contact-details",
        })
        .then(async (response: any) => {
          if (response.success) {
            contactDescrip.value = response.block_data;
          } else {
            t("apiMsgs." + response.error.code);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function getMapBlock() {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("cmsBlock"), {
          identifier: "contact-map",
        })
        .then(async (response: any) => {
          if (response.success) {
            contactMap.value = response.block_data.content;
          } else {
            t("apiMsgs." + response.error.code);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    onMounted(() => {
      getCmsBlock();
      getMapBlock();
      getSubjectOptions();
    });

    return {
      t,
      icons,
      contact,
      contactAction,
      contactDescrip,
      subjectOptions,
      contactMap,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    MainHeader,
    MainFooter,
  },
});
